<template>
    <div>
        <!--Are You Sure Remove Pop Up-->
        <b-modal no-close-on-backdrop ok-title="Submit" title="Confirm Remove" class="modal-dark"
                 v-model="openAreYouSureRemove"
                 @ok="removeMicroservice(currentMicroservice._id)">
            <p>Are you sure you want to remove this microservice?</p>
        </b-modal>
        <!--Successfully Deleted Modal-->
        <b-modal scrollable title="Success" class="modal-dark" v-model="openSuccessDelete" ok-only
                 @ok="openServices=false">
            <p>You have successfully deleted the microservice.</p>
        </b-modal>
        <!--Edit Pop-Up-->
        <b-modal scrollable :title="currentMicroservice.name + ' Information'" class="modal-dark" v-model="openServices"
                 @ok="openServices=false">
            <h5 style="text-align:center" v-if="!currentMicroservice.dockerInfo">This microservice is no longer
                active.</h5>
            <p v-else v-for="(value,name) in currentMicroservice.dockerInfo" :key="name"> {{ name }} : {{ value }}</p>
            <template slot="modal-footer">
                <div class="mx-auto">
                    <b-button size="sm" style="width:70px" @click="restartMicroservice(currentMicroservice._id)"
                              variant="primary">Restart
                    </b-button>
                    <b-button size="sm" style="width:70px" class="mx-2"
                              @click="stopMicroservice(currentMicroservice._id)" variant="warning">Stop
                    </b-button>
                    <b-button size="sm" style="width:70px" @click="areYouSureRemove" variant="danger">Remove</b-button>
                </div>
            </template>
        </b-modal>


        <!--Table-->
        <b-card no-body>
            <b-card-header>
                <div class="row">
                    <div class="col-sm-4" style="margin-top: 8px">
                        <h5><b>{{ constellation.constellationname }} Microservices</b></h5>
                    </div>
                    <div class="col-sm-8">
                        <b-nav style="float:right">
                            <b-nav-item-dropdown text="Elasticband Cleanup">
                                <b-dropdown-item @click="startElasticCleanup">Start All</b-dropdown-item>
                                <b-dropdown-item @click="stopElasticCleanup">Stop All</b-dropdown-item>
                                <b-dropdown-item @click="restartElasticCleanup">Restart All</b-dropdown-item>
                                <b-dropdown-item @click="removeElasticCleanup">Remove All</b-dropdown-item>
                            </b-nav-item-dropdown>
                            <b-nav-item-dropdown text="FM SNMP" style="float: right">
                                <b-dropdown-item @click="startSnmp">Start All</b-dropdown-item>
                                <b-dropdown-item @click="stopSnmp">Stop All</b-dropdown-item>
                                <b-dropdown-item @click="restartSnmp">Restart All</b-dropdown-item>
                                <b-dropdown-item @click="removeSnmp">Remove All</b-dropdown-item>
                            </b-nav-item-dropdown>
                            <b-nav-item-dropdown text="Elasticband" style="float: right">
                                <b-dropdown-item @click="startBand">Start All</b-dropdown-item>
                                <b-dropdown-item @click="stopBand">Stop All</b-dropdown-item>
                                <b-dropdown-item @click="restartBand">Restart All</b-dropdown-item>
                                <b-dropdown-item @click="removeBand">Remove All</b-dropdown-item>
                            </b-nav-item-dropdown>
                            <b-nav-item-dropdown text="FM Ping">
                                <b-dropdown-item @click="startPing">Start All</b-dropdown-item>
                                <b-dropdown-item @click="stopPing">Stop All</b-dropdown-item>
                                <b-dropdown-item @click="restartPing">Restart All</b-dropdown-item>
                                <b-dropdown-item @click="removePing">Remove All</b-dropdown-item>
                            </b-nav-item-dropdown>
                        </b-nav>
                    </div>

                </div>
            </b-card-header>
            <b-card-body>
                <v-client-table @row-click="rowClicked" style="width: 100%" :columns="columns" :data="microservices"
                                :options="options" :theme="theme"
                                class="dataTable">
                    <a slot="info" slot-scope="props" target="_blank" :href="props.row.info"
                       style="float: right; cursor:pointer;"
                       @click="moreInfo(props.row._id, true)">
                        <fa-icon :icon="['fas', 'info']"/>
                    </a>
                    <div slot="h__info">
                        <p style="float: right; margin-bottom: 0">info</p>
                    </div>
                    <div slot="image" slot-scope="props">
                        <b-badge :variant="getBadge(props.row)">{{ props.row.image }}</b-badge>
                    </div>
                </v-client-table>

                <b-tooltip v-if="tooltipRender" ref="tooltip" :target="target" :title="tooltipTitle"
                           triggers=""></b-tooltip>
            </b-card-body>
        </b-card>
    </div>

</template>

<script>
import constellations from '../../../services/constellation.js'
import constellationStore from "@/store/constellationStore";
import PubSub from "pubsub-js";
import Vue from 'vue'
import {ClientTable} from 'vue-tables-2'

Vue.use(ClientTable);

export default {
    name: "Microservices",
    props: ["constellationId"],
    data: () => {
        return {
            pubsub: null,
            tooltipTitle: "",
            tooltipRender: true,
            target: '',
            openAreYouSureRemove: false,
            constellation: {},
            openServices: false,
            currentMicroservice: {},
            microservices: [],
            openSuccessDelete: false,

            //Data Table
            columns: ['image', 'name', 'info'],
            options: {
                orderBy: {column: 'name', ascending: true},
                headings: {
                    image: 'Image',
                    name: 'Name',
                    info: 'Info'
                },
                sortable: ['image', 'microservices', 'name'],
                filterable: ['image', 'microservices', 'name'],
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
            },
            useVuex: false,
            theme: 'bootstrap4',
            template: 'default',
        }
    },
    created() {
        this.getConstellation(true);
    },
    beforeDestroy() {
        PubSub.unsubscribe(this.pubsub);
    },
    methods: {
        getConstellation(initial = false) {
            constellationStore.dispatch('getConstellation', this.constellationId).then(response => {
                this.constellation = response;
                this.getMicroservices(true);
                this.pubsub = PubSub.subscribe('constellationupdate-' + this.constellationId, (msg, data) => {
                    this.constellation = data;
                    this.getMicroservices(true);
                });
            }).catch(() => {
                this.$mToast({
                    title: 'Error',
                    text: "Constellation info couldn't be retrieved",
                    style: 'error'
                });
            })
        },
        getMicroservices(initial = false) {
            constellations.getMicroservices(this.constellation.url + ":" + this.constellation.port).then(response => {
                this.microservices = response.data;
                /*response.data.forEach(service => {
                    constellations.getMicroservice(this.constellation.url + ":" + this.constellation.port, service._id).then(response1 => {
                    })
                })*/
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Microservice info couldn't be retrieved: " + error.response.statusText,
                    style: 'error'
                });
            });
        },
        moreInfo(id, modalStatus = false) {
            constellations.getMicroservice(this.constellation.url + ":" + this.constellation.port, id).then(response => {
                this.currentMicroservice = response.data;
                this.openServices = modalStatus;
            }).catch(() => {
                this.$mToast({
                    title: 'Error',
                    text: "Additional microservice info couldn't be retrieved",
                    style: 'error'
                });
            });
        },
        areYouSureRemove() {
            this.openAreYouSureRemove = true;
        },
        restartMicroservice(id) {
            constellations.restartMicroservice(this.constellation.url + ":" + this.constellation.port, id).then(() => {
                this.openServices = false;
                this.moreInfo(id);
                this.$mToast({
                    title: 'Success',
                    text: "Microservice restarted",
                    style: 'success'
                });
            }).catch(() => {
                this.$mToast({
                    title: 'Error',
                    text: "Microservice couldn't be restarted",
                    style: 'error'
                });
            });
        },
        stopMicroservice(id) {
            constellations.stopMicroservice(this.constellation.url + ":" + this.constellation.port, id).then(() => {
                this.openServices = false;
                this.moreInfo(id);
                this.$mToast({
                    title: 'Success',
                    text: "Microservice stopped",
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Microservice couldn't be stopped: " + error.response.statusText,
                    style: 'error'
                });
            });
        },
        removeMicroservice(id) {
            constellations.removeMicroservice(this.constellation.url + ":" + this.constellation.port, id).then(() => {
                this.openServices = false;
                this.moreInfo(id);
                this.openSuccessDelete = true;
                this.$mToast({
                    title: 'Success',
                    text: "Microservice restarted",
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Microservice couldn't be removed: " + error.response.statusText,
                    style: 'error'
                });
            });
        },
        startElasticCleanup() {
            constellations.startElasticBandCleanup(this.constellation.url + ":" + this.constellation.port).then(() => {
                this.getMicroservices();
                this.$mToast({
                    title: 'Success',
                    text: "Elastic cleanup started",
                    style: 'success'
                });
            })
                .catch(error => {
                    this.$mToast({
                        title: error.response.status + ' Error',
                        text: "Elastic cleanup couldn't be started: " + error.response.statusText,
                        style: 'error'
                    });
                })
        },
        stopElasticCleanup() {
            constellations.stopElasticBandCleanup(this.constellation.url + ":" + this.constellation.port).then(() => {
                this.getMicroservices();
                this.$mToast({
                    title: 'Success',
                    text: "Elastic cleanup stopped",
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Elastic cleanup couldn't be stopped: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        restartElasticCleanup() {
            constellations.restartElasticBandCleanup(this.constellation.url + ":" + this.constellation.port).then(() => {
                this.getMicroservices();
                this.$mToast({
                    title: 'Success',
                    text: "Elastic cleanup restarted",
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Elastic cleanup couldn't be restarted: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        removeElasticCleanup() {
            constellations.removeElasticBandCleanup(this.constellation.url + ":" + this.constellation.port).then(() => {
                this.getMicroservices();
                this.$mToast({
                    title: 'Success',
                    text: "Elastic cleanup removed",
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Elastic cleanup couldn't be removed: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        startSnmp() {
            constellations.startSnmp(this.constellation.url + ":" + this.constellation.port).then(() => {
                this.getMicroservices();
                this.$mToast({
                    title: 'Success',
                    text: "SNMP started",
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "SNMP couldn't be started: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        stopSnmp() {
            constellations.stopSnmp(this.constellation.url + ":" + this.constellation.port).then(() => {
                this.getMicroservices();
                this.$mToast({
                    title: 'Success',
                    text: "SNMP stopped",
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "SNMP couldn't be stopped: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        restartSnmp() {
            constellations.restartSnmp(this.constellation.url + ":" + this.constellation.port).then(() => {
                this.getMicroservices();
                this.$mToast({
                    title: 'Success',
                    text: "SNMP restarted",
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "SNMP couldn't be restarted: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        removeSnmp() {
            constellations.removeSnmp(this.constellation.url + ":" + this.constellation.port).then(() => {
                this.getMicroservices();
                this.$mToast({
                    title: 'Success',
                    text: "SNMP removed",
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "SNMP couldn't be removed: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        startBand() {
            constellations.startElasticBand(this.constellation.url + ":" + this.constellation.port).then(() => {
                this.getMicroservices();
                this.$mToast({
                    title: 'Success',
                    text: "Band started",
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Band couldn't be started: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        stopBand() {
            constellations.stopElasticBand(this.constellation.url + ":" + this.constellation.port).then(() => {
                this.getMicroservices();
                this.$mToast({
                    title: 'Success',
                    text: "Band stopped",
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Band couldn't be stopped: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        restartBand() {
            constellations.restartElasticBand(this.constellation.url + ":" + this.constellation.port).then(() => {
                this.getMicroservices();
                this.$mToast({
                    title: 'Success',
                    text: "Band restarted",
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Band couldn't be restarted: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        removeBand() {
            constellations.removeElasticBand(this.constellation.url + ":" + this.constellation.port).then(() => {
                this.getMicroservices();
                this.$mToast({
                    title: 'Success',
                    text: "Band removed",
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Band couldn't be removed: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        startPing() {
            constellations.startPing(this.constellation.url + ":" + this.constellation.port).then(() => {
                this.getMicroservices();
                this.$mToast({
                    title: 'Success',
                    text: "Ping started",
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Ping couldn't be started: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        stopPing() {
            constellations.stopPing(this.constellation.url + ":" + this.constellation.port).then(() => {
                this.getMicroservices();
                this.$mToast({
                    title: 'Success',
                    text: "Ping stopped",
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Ping couldn't be stopped: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        restartPing() {
            constellations.restartPing(this.constellation.url + ":" + this.constellation.port).then(() => {
                this.getMicroservices();
                this.$mToast({
                    title: 'Success',
                    text: "Ping restarted",
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Ping couldn't be restarted: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        removePing() {
            constellations.removePing(this.constellation.url + ":" + this.constellation.port).then(() => {
                this.getMicroservices();
                this.$mToast({
                    title: 'Success',
                    text: "Ping removed",
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Ping couldn't be removed: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        rowClicked(item) {
            //ctrl click to make tooltip appear with id
            if (item.event.ctrlKey) {
                this.tooltipRender = false;
                this.tooltipTitle = item.row._id;
                item.event.target.id = item.row._id;
                this.target = item.row._id;
                //restart tooltip so it can map to its new position
                Vue.nextTick().then(() => {
                    this.tooltipRender = true;
                    Vue.nextTick().then(() => {
                        this.$refs.tooltip.$emit('open')
                        setTimeout(function () {
                            //make the tooltip disapear automatically
                            this.$refs.tooltip.$emit('close');
                        }.bind(this), 4000)
                    });
                });
            }
        },
        getBadge(status) {
            return !status.dockerInfo ? 'danger'
                : (status.dockerInfo || {}).Status === 'running' ? 'success' : 'warning'
        },
    },
};
</script>
